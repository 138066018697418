import { useState, useRef } from 'react';
import { REQUEST } from '../utils/constants';

type Fetch<T> = {
  data: T | null;
  status: number | null;

  error: boolean;
  loading: boolean;

  clear: () => void;
  refetch: (payload?: any) => Promise<void>;
};

export function useFetch<T = any>(path: string, method?: string): Fetch<T> {
  const [data, setData] = useState<T | null>(null);
  const [status, setStatus] = useState<number | null>(null);

  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);

  const clear = () => {
    setData(null);
    setStatus(null);
    setError(false);
    setLoading(false);
  };

  const refetch = async (payload?: any): Promise<void> => {
    setStatus(null);
    setError(false);
    setLoading(true);

    const url = REQUEST.URL + path;

    try {
      const response = await fetch(url, {
        method: method ? method : 'GET',
        headers: { 'Content-Type': 'application/json' },
        body: payload ? JSON.stringify(payload) : undefined,
      });

      setStatus(response.status);

      if (response.ok) {
        response
          .json()
          .then((data: T) => setData(data))
          .catch((reason) => console.info(reason));
      } else {
        throw new Error(
          'Request responded with status code ' + response.status,
        );
      }
    } catch (error) {
      console.error(error);
      setError(true);
    } finally {
      setLoading(false);
    }
  };

  const refetchRef = useRef(refetch);

  return {
    data,
    status,
    loading,
    error,
    clear,
    refetch: refetchRef.current,
  };
}
