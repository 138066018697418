import React from 'react';
import styled from 'styled-components';

import { Link } from 'react-router-dom';
import { Layout, Typography } from 'antd';
import { Container } from './Container';
import { COLOR_CODES, FONTS } from '../utils/constants';
import { pathNames } from '../utils/routes';

const CustomLayout = styled(Layout)`
  background-size: cover;
  background-position: center;
  background-color: #fff;
  background-image: url('images/background.png');
`;

const Header = styled(Layout.Header)`
  height: auto;
  max-height: 130px;

  padding: 20px 0px;
  background-color: transparent;
`;

const Logo = styled.img`
  width: 100%;
  padding: 10px;

  @media (min-width: 576px) {
    width: 85%;
    width: auto;
    height: 90px;
  }

  @media (min-width: 768px) {
    width: auto;
    height: 90px;
  }
`;

const Content = styled(Layout.Content)`
  overflow: auto;
  min-height: calc(100vh - 200px);

  @media (min-width: 768px) {
    height: calc(100vh - 200px);
  }
`;

const Footer = styled(Layout.Footer)`
  width: 100%;
  z-index: 10;
  background-color: ${COLOR_CODES.HSV.BLUE};

  @media (min-width: 768px) {
    max-height: 70px;
  }
`;

const FooterList = styled.ul`
  display: flex;
  align-items: center;
  justify-content: center;

  margin: 0;
  padding: 0;
  list-style: none;

  @media (max-width: 767px) {
    flex-direction: column;
  }
`;

const FooterListItem = styled.li`
  padding: 10px 10px;

  @media (min-width: 768px) {
    padding: 0 18px;
  }

  @media (min-width: 992px) {
    padding: 0 25px;
  }
`;

const linkStyles = `
  color: #fff !important;
  font-family: ${FONTS.HSV.SLAB.BOLD};
  font-size: 16px;
  font-weight: 600;
  text-transform: uppercase;
`;

const InternalLink = styled(Link)`
  ${linkStyles}
`;

const ExternalLink = styled(Typography.Link)`
  ${linkStyles}
`;

interface FooterListEntry {
  text: string;
  link: string;
  type: 'internal' | 'external';
}

const FOOTER: FooterListEntry[] = [
  { text: 'Kontakt', link: pathNames.contact, type: 'external' },
  { text: 'Impressum', link: pathNames.publisher, type: 'external' },
  { text: 'Datenschutz', link: pathNames.privacy, type: 'external' },
];

const PageLayout: React.FC = ({ children }) => {
  return (
    <CustomLayout>
      <Header>
        <Container>
          <Link to={pathNames.main}>
            <Logo alt="HSV Stiftung Logo" src="logos/HSV-Stiftung1.png" />
          </Link>
        </Container>
      </Header>
      <Content>{children}</Content>
      <Footer>
        <FooterList>
          {FOOTER.map((item) => (
            <FooterListItem key={item.text}>
              {item.type === 'internal' ? (
                <InternalLink to={item.link}>{item.text}</InternalLink>
              ) : (
                <ExternalLink href={item.link}>{item.text}</ExternalLink>
              )}
            </FooterListItem>
          ))}
        </FooterList>
      </Footer>
    </CustomLayout>
  );
};

export default PageLayout;
