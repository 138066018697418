import { RouteProps } from 'react-router';

import Main from '../pages/Main';

export const pathNames = {
  main: '/',
  privacy: 'https://www.hsv.de/datenschutz',
  contact: 'mailto:stiftung@hsv.de',
  publisher: 'https://www.hsv.de/ueber-uns/impressum',
};

export interface IRoute {
  component: RouteProps['component'];
  exact?: boolean;
  path: string;
}

const RoutesObject = <T>(routes: { [K in keyof T]: IRoute }) => routes;

export const ROUTES = RoutesObject({
  MainPage: {
    component: Main,
    path: pathNames.main,
  },
});

export type RouteName = keyof typeof ROUTES;
