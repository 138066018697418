import styled from 'styled-components';

import { Button } from 'antd';
import { COLOR_CODES, FONTS } from '../utils/constants';

export const FulfillWishButton = styled(Button)`
  font-family: ${FONTS.HSV.SLAB.BOLD};
  font-weight: 600;
  line-height: 1.35;
  font-size: 12px;
  height: 44px;

  text-align: center;

  border: 2px solid ${COLOR_CODES.HSV.BLUE};

  &:hover {
    color: ${COLOR_CODES.HSV.BLUE};
    border-color: ${COLOR_CODES.HSV.BLUE};
  }

  &:focus {
    border-color: ${COLOR_CODES.HSV.BLUE};
  }

  @media (min-width: 576px) {
    font-size: 14px;
  }

  @media (min-width: 768px) {
    font-size: 16px;
  }
`;

export const DrawNewWishButton = styled(Button)`
  color: ${COLOR_CODES.HSV.BLUE};
  margin-top: 20px;
`;
