import { Modal, Result, Skeleton } from 'antd';
import styled from 'styled-components';
import React from 'react';

import { Wish } from '../../../types/Response';

import ChristmasWish from './ChristmasWish';

import {
  DrawNewWishButton,
  FulfillWishButton,
} from '../../../components/Button';

import { TitleSlab, Text } from '../../../components/Typography';

const CustomSkeleton = styled(Skeleton)`
  margin-bottom: 6rem;
`;

interface DrawnWishModalProps {
  drawnWish: Wish | null;
  displayWish: boolean;

  loadingWish: boolean;
  loadingReserveStatus: boolean;

  refetchStatus: number | null;
  reservedStatus: number | null;

  refetchWish: () => Promise<void>;
  reserveWish: (payload?: any) => Promise<void>;
  setDisplayWish: (value: React.SetStateAction<boolean>) => void;
}

const DrawnWishModal: React.FC<DrawnWishModalProps> = (props) => {
  const onCancel = () => {
    props.setDisplayWish(false);
  };

  return (
    <Modal
      centered
      footer={null}
      visible={props.displayWish}
      onCancel={onCancel}
    >
      {!props.loadingWish && props.drawnWish ? (
        <ChristmasWish
          wishId={props.drawnWish.nummer}
          wishDescription={props.drawnWish.beschreibung_wunsch}
          childAge={props.drawnWish.alter}
          childName={props.drawnWish.name_der_person}
          organization={props.drawnWish.name_der_einrichtung}
        />
      ) : props.refetchStatus === 404 ? (
        <Result
          extra={
            <>
              <TitleSlab level={4}>Lieber Wunscherfüller,</TitleSlab>
              <Text>
                derzeit hängt kein Wunsch mehr am Weihnachtsbaum der
                HSV-Stiftung. Wir werden in Kürze neue Wünsche in den Baum
                hängen und bitten dich um etwas Geduld. Versuche es bitte später
                erneut.
              </Text>
              <br />
              <br />
              <TitleSlab level={5}>Weihnachtliche Grüße</TitleSlab>
              <TitleSlab level={4}>Dein Team der HSV-Stiftung</TitleSlab>
            </>
          }
        />
      ) : (
        <>
          <Skeleton active />
          <CustomSkeleton active />
        </>
      )}

      {props.loadingReserveStatus ? (
        <FulfillWishButton block loading type="default">
          Lade
        </FulfillWishButton>
      ) : (
        <FulfillWishButton
          block
          type="default"
          disabled={props.loadingWish || props.refetchStatus === 404}
          onClick={() =>
            props.reserveWish({ nummer: props!.drawnWish!.nummer })
          }
        >
          Wunsch erfüllen
        </FulfillWishButton>
      )}

      <DrawNewWishButton
        block
        type="link"
        disabled={
          props.loadingWish ||
          props.refetchStatus === 404 ||
          props.loadingReserveStatus
        }
        onClick={() => props.refetchWish()}
      >
        Neuen Wunsch ziehen
      </DrawNewWishButton>
    </Modal>
  );
};

export default DrawnWishModal;
