import React, { useEffect, useState } from 'react';
import { Col, notification, message, Typography } from 'antd';

import styled from 'styled-components';
import ChristmasTree from './components/ChristmasTree';
import UserFormModal from './components/UserFormModal';
import DrawnWishModal from './components/DrawnWishModal';

import { ContainerRow } from '../../components/Container';
import { Pull, Wish } from '../../types/Response';
import { useFetch } from '../../hooks/usefetch';
import { REQUEST } from '../../utils/constants';

import InfoText from './components/InfoText';

const ImageCol = styled(Col)`
  @media (max-width: 767px) {
    height: 735px;
  }
`;

const displayErrorMessage = () => {
  message.error(
    'Ein Fehler ist aufgetreten. Bitte versuchen Sie es später erneut.',
  );
};

const displayWishWasTaken = () => {
  notification.info({
    duration: 12,
    message: 'Lieber Wunscherfüller,',
    description: (
      <>
        <Typography.Text>
          dein ausgewählter Wunsch ist leider nicht mehr verfügbar. Bitte
          versuche es nochmal und wähle einen anderen Wunsch aus.
          <br />
          <br />
          Weihnachtliche Grüße
          <br />
          Dein Team der HSV-Stiftung
        </Typography.Text>
      </>
    ),
  });
};

const Main: React.FC = () => {
  const {
    error,
    data: drawnWish,
    clear: clearWish,
    loading: loadingWish,
    refetch: refetchWish,
    status: refetchStatus,
  } = useFetch<Wish>(REQUEST.ENDPOINTS.WISH);

  const {
    data: reservedWish,
    error: reserveError,
    refetch: reserveWish,
    status: reservedStatus,
    clear: clearReservedStatus,
    loading: loadingReserveStatus,
  } = useFetch<Pull>(REQUEST.ENDPOINTS.PULL, 'POST');

  const [displayWish, setDisplayWish] = useState<boolean>(false);

  const [displayUserForm, setDisplayUserForm] = useState<boolean>(false);

  const onChristmasTreeClick = () => {
    refetchWish();
    setDisplayWish(true);
  };

  useEffect(() => {
    if (reservedWish) {
      setDisplayWish(false);
      setDisplayUserForm(true);
    }
  }, [reservedWish, setDisplayUserForm]);

  useEffect(() => {
    if (error) {
      if (refetchStatus !== 404) {
        displayErrorMessage();
      }
      return;
    }
    if (reserveError) {
      // A 403 means the wish was already taken.
      if (reservedStatus === 403) {
        displayWishWasTaken();
      } else {
        displayErrorMessage();
      }
      clearReservedStatus();
    }
  }, [
    error,
    reserveError,
    refetchStatus,
    reservedStatus,
    setDisplayWish,
    clearReservedStatus,
  ]);

  return (
    <>
      {reservedWish && drawnWish && displayUserForm ? (
        <UserFormModal
          clearWish={clearWish}
          reservedWish={{
            name: drawnWish.name_der_person,
            nummer: reservedWish.nummer,
            gezogen_timestamp: reservedWish.gezogen_timestamp,
          }}
          displayUserForm={displayUserForm}
          setDisplayUserForm={setDisplayUserForm}
        />
      ) : (
        <DrawnWishModal
          drawnWish={drawnWish}
          loadingWish={loadingWish}
          loadingReserveStatus={loadingReserveStatus}
          displayWish={displayWish}
          refetchStatus={refetchStatus}
          reservedStatus={reservedStatus}
          refetchWish={refetchWish}
          reserveWish={reserveWish}
          setDisplayWish={setDisplayWish}
        />
      )}

      <ContainerRow>
        <Col xs={24} sm={24} md={12} lg={12} xl={10}>
          <InfoText />
        </Col>
        <ImageCol xs={24} sm={24} md={12} lg={12} xl={14}>
          <ChristmasTree
            onClick={onChristmasTreeClick}
            christmasTreeImage={{
              url: 'images/christmas-tree.svg',
              width: 950,
              height: 1000,
            }}
            // christmasSpherePositions={[{ size: 7, bottom: 76.8, right: 51.2 }]}
          />
        </ImageCol>
      </ContainerRow>
    </>
  );
};

export default Main;
