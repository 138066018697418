import React from 'react';

import {
  TitleSlab,
  TitleSans,
  Description,
  TitleSlabLarge,
} from '../../../components/Typography';

const InfoText: React.FC = () => {
  return (
    <Description>
      <TitleSlabLarge level={1}>WEIHNACHTSTAG 2024</TitleSlabLarge>
      <TitleSans level={5}>
        Die HSV-Stiftung veranstaltet in diesem Jahr zum 17. Mal den
        Weihnachtstag. Über verschiedene soziale Organisationen wurden Wünsche
        von Kindern und Jugendlichen eingesammelt.
      </TitleSans>
      <TitleSlab level={2}>Der Weihnachtsbaum der HSV-Stiftung</TitleSlab>
      <TitleSans level={5}>
        Klicke auf „Wunsch ziehen“, um dir einen Wunschzettel anzeigen zu
        lassen. Wenn du den Wunsch erfüllen möchtest, gib bitte deinen Namen und
        deine E-Mail-Adresse ein. Anschließend erhältst du weitere Informationen
        per E-Mail. Möchtest du lieber einen anderen Wunsch erfüllen? Klicke auf
        „Weiteren Wunsch anzeigen“.
      </TitleSans>
      <TitleSans level={5}>
        Wir danken dir vielmals für deine Unterstützung und wünschen dir eine
        schöne Vorweihnachtszeit!
      </TitleSans>
      <TitleSlab level={4}>Dein Team der HSV-Stiftung</TitleSlab>
    </Description>
  );
};

export default InfoText;
