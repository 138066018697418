import React from 'react';
import styled from 'styled-components';

import { Button } from 'antd';
import { COLOR_CODES } from '../../../utils/constants';

const Position = styled.div`
  position: absolute;
  text-align: center;
`;

const Icon = styled.span`
  width: 100%;
  height: 100%;
  background-size: 100% 100%;
  background-image: url(images/christmas-sphere.png);
`;

const CustomButton = styled(Button)`
  width: 100%;
  height: 100%;

  background: none;
  border-color: transparent;
  background-color: transparent;

  ::selection {
    background: none;
  }

  &:hover {
    border-color: ${COLOR_CODES.HSV.BLUE};
    background-color: ${COLOR_CODES.HSV.BLUE};
  }
`;

interface ChristmasSphereProps {
  size: number;
  right: number;
  bottom: number;
  onClick: () => void;
}

/**
 * This functional component was build for testing the use case that
 * a christmas tree can have clickable christmas spheres. If there is
 * enough time and money left this implementation could be finalized.
 */
const ChristmasSphere: React.FC<ChristmasSphereProps> = (props) => {
  return (
    <Position
      style={{
        right: `${props.right}%`,
        bottom: `${props.bottom}%`,
        width: `${props.size}%`,
        height: `${props.size}%`,
      }}
    >
      <CustomButton
        type="primary"
        shape="circle"
        onClick={props.onClick}
        icon={<Icon />}
      />
    </Position>
  );
};

export default ChristmasSphere;
