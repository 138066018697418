import React from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import { RouteName, ROUTES } from './utils/routes';

import './styles/App.css';
import PageLayout from './components/PageLayout';

const Routes = (): JSX.Element => {
  return (
    <Switch>
      {Object.keys(ROUTES).map((routeName: string) => {
        const { component, exact, path } = ROUTES[routeName as RouteName];

        return (
          <Route
            key={routeName}
            exact={exact}
            path={path}
            component={component}
          />
        );
      })}
    </Switch>
  );
};

const App: React.FC = (): JSX.Element => {
  return (
    <BrowserRouter>
      <PageLayout>
        <Routes />
      </PageLayout>
    </BrowserRouter>
  );
};

export default App;
