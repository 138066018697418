export const REQUEST = {
  URL: process.env.REACT_APP_API_URL,
  ENDPOINTS: {
    WISH: '/wish',
    PULL: '/pull',
    UNBLOCK: '/unblock',
    SATISFY: '/satisfy',
  },
};

export const COLOR_CODES = {
  HSV: {
    RED: '#ED1C24',
    BLUE: '#005AAA',
    BLACK: '#000000',
    WHITE: '#FFFFFF',
  },
};

export const FONTS = {
  HSV: {
    SANS: {
      REGULAR: 'HSV Sans Regular',
      BOLD: 'HSV Sans Bold',
      ITALIC: 'HSV Sans Italic',
    },
    SLAB: {
      REGULAR: 'HSV Slab Regular',
      BOLD: 'HSV Slab Bold',
      ITALIC: 'HSV Slab Italic',
    },
  },
};
