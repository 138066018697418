import styled from 'styled-components';

import { Row } from 'antd';

const containerStyle = `
  width: 100%;

  padding-right: 15px;
  padding-left: 15px;

  margin-right: auto;
  margin-left: auto;

  @media (min-width: 576px) {
  max-width: 620px;
  }

  @media (min-width: 768px) {
  max-width: 720px;
  }

  @media (min-width: 992px) {
  max-width: 960px;
  }

  @media (min-width: 1200px) {
  max-width: 1140px;
  }

  @media (min-width: 1600px) {
    max-width: 1400px;
  }
`;

export const Container = styled.div`
  ${containerStyle}
`;

export const ContainerRow = styled(Row)`
  ${containerStyle}

  height: 100%;
  overflow: visible;

  @media (max-width: 767px) {
    overflow: hidden;
  }
`;
