import React from 'react';
import styled from 'styled-components';
import { Text, Value } from '../../../components/Typography';

const Content = styled.div`
  margin-bottom: 60px;

  @media (min-width: 768px) {
    margin-bottom: 84px;
  }
`;

interface ChristmasWishProps {
  wishId: number;
  wishDescription: string;
  childAge: string;
  childName: string;
  organization: string;
}

const ChristmasWish: React.FC<ChristmasWishProps> = (props) => {
  return (
    <Content>
      <Text>Wunschnummer:</Text>
      <Value>{props.wishId}</Value>

      <Text>Ich heiße:</Text>
      <Value>{props.childName}</Value>

      <Text>Alter:</Text>
      <Value>{props.childAge}</Value>

      <Text>Mein Wunsch:</Text>
      <Value>{props.wishDescription}</Value>

      <Text>Organisation:</Text>
      <Value>{props.organization}</Value>
    </Content>
  );
};

export default ChristmasWish;
