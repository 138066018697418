import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

import useResizeAware from 'react-resize-aware';
import ChristmasSphere from './ChristmasSphere';

const ImageWrapper = styled.div`
  position: fixed;
  bottom: 0px;
  right: -130px;

  width: 100%;
  height: 95%;

  @media (max-width: 767px) {
    position: absolute;
  }

  @media (min-width: 460px) {
    right: -70px;
  }

  @media (min-width: 576px) {
    right: -25px;
  }

  @media (min-width: 768px) {
    right: -220px;
    height: 90%;
  }

  @media (min-width: 992px) {
    right: -100px;
    height: 95%;
  }

  @media (min-width: 1200px) {
    right: -20px;
    height: 96%;
  }

  @media (min-width: 1600px) {
    right: 100px;
    height: 97%;
  }
`;

const ChristmasTreeImage = styled.div`
  position: relative;

  width: 100%;
  height: 100%;

  cursor: pointer;

  background-size: 110% 110%;
  background-repeat: no-repeat;

  @media (min-width: 768px) {
    background-size: 104% 104%;
  }
`;

type ChristmasSpherePosition = {
  size: number;
  right: number;
  bottom: number;
};

type ChristmasTreeImage = {
  url: string;
  width: number;
  height: number;
};

interface ChristmasTreeProps {
  onClick: () => void;
  christmasTreeImage: ChristmasTreeImage;
  christmasSpherePositions?: ChristmasSpherePosition[];
}

const ChristmasTree: React.FC<ChristmasTreeProps> = (props) => {
  const widthToHeightRatio =
    props.christmasTreeImage.width / props.christmasTreeImage.height;

  const [resizeListener, sizes] = useResizeAware();

  const [calculatedWidth, setCalculatedWidth] = useState<number>(
    sizes.height
      ? Math.floor(sizes.height * widthToHeightRatio)
      : props.christmasTreeImage.width,
  );

  useEffect(() => {
    if (sizes.height) {
      setCalculatedWidth(Math.floor(sizes.height * widthToHeightRatio));
    }
  }, [sizes.height, widthToHeightRatio]);

  return props.christmasSpherePositions ? (
    <ImageWrapper style={{ width: `${calculatedWidth}px` }}>
      {resizeListener}
      <ChristmasTreeImage
        style={{ backgroundImage: `url(${props.christmasTreeImage.url})` }}
      >
        {props.christmasSpherePositions.map((position) => {
          return (
            <ChristmasSphere
              key={`${position.right} ${position.bottom}`}
              size={position.size}
              right={position.right}
              bottom={position.bottom}
              onClick={props.onClick}
            />
          );
        })}
      </ChristmasTreeImage>
    </ImageWrapper>
  ) : (
    <ImageWrapper
      style={{ width: `${calculatedWidth}px` }}
      onClick={props.onClick}
    >
      {resizeListener}
      <ChristmasTreeImage
        style={{
          backgroundImage: `url(${props.christmasTreeImage.url})`,
        }}
      />
    </ImageWrapper>
  );
};

export default ChristmasTree;
