import styled from 'styled-components';

import { Typography } from 'antd';
import { COLOR_CODES, FONTS } from '../utils/constants';

export const TitleSlabLarge = styled(Typography.Title)`
  font-family: ${FONTS.HSV.SLAB.BOLD};
  font-size: 32px;
  line-height: 38.4px;
`;

export const TitleSlab = styled(Typography.Title)`
  font-family: ${FONTS.HSV.SLAB.BOLD};
  font-size: 14px;
  line-height: 23px;
`;

export const TitleSans = styled(Typography.Title)`
  font-family: ${FONTS.HSV.SANS.BOLD};
  font-size: 14px;
  line-height: 23px;
`;

export const Text = styled(Typography.Text)`
  color: ${COLOR_CODES.HSV.BLUE};
`;

export const Value = styled.h3`
  margin-top: 10px;
  margin-bottom: 16px;

  color: rgba(0, 0, 0, 0.85);

  font-size: 16px;
  font-family: ${FONTS.HSV.SLAB.BOLD};
  font-weight: 600;
  line-height: 1.35;

  @media (min-width: 768px) {
    margin-top: 10px;
    margin-bottom: 24px;

    font-size: 20px;
  }
`;

export const Description = styled.div`
  margin: 30px 28px;

  @media (min-width: 992px) {
    margin: 100px 28px;
  }
`;
